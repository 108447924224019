import styled from 'styled-components';

const MediumFont = 'Rubik-Medium';

const MainContainer = styled.div`
margin-bottom: 25px;
width: 100%;
// height: 100%;

  > div:first-child {
    text-transform: Capitalize;
    font-size: 18px;
    color: #0D4270;
    font-family: ${MediumFont};
    margin-bottom: 15px;
  }
  > div: last-child {
    > div {
      > div: first-child {
        margin-top: 0px;
      }
    }
  }
`;
const SubContainer = styled.div`
width: 100%;
height: 90px;
background-color: #FFF;
margin-top: 10px;
cursor: pointer;
border-radius: 4px;
`;
const ImageContainer = styled.div`
width: 27%;
padding: 10px;
float: left;
  > img {
    width: 75px;
  }
  @media(max-width: 766px) {
    width: 14%;
  }
  @media(max-width: 600px) {
    width: 20%;
  }
  @media(max-width: 430px) {
    width: 30%;
  }
`;
const DataContainer = styled.div`
width: 65%;
height: 100%;
float: left;
padding: 10px;
display: flex;
justify-content: space-evenly;
flex-flow: column;

  > div: first-child {
    font-size: 18px;
    font-family: 'Rubik-Medium';
    line-height: 21px;
    color: #0D4270;
    font-weight: 500;
  }
  > div: last-child {
    font-family: 'Rubik-Medium';
    font-size: 16px;
    line-height: 19px;
    font-weight: 400;
    color: ${({color}) => color};
  }
  @media(max-width: 766px) {
    width: 76%;
  }
  @media(max-width: 600px) {
    width: 72%;
  }
  @media(max-width: 430px) {
    width: 62%;
  }
`;
const Arrow = styled.div`
width: 8%;
height: 100%;
float: left;
display: flex;
justify-content: center;
  > img {
    height: 16px;
    align-self: center;
  }

`;
export {
  MainContainer, SubContainer, ImageContainer, DataContainer, Arrow
};
