import React, {Component} from 'react';
import "./styles.css";
import {Button} from "../HowItWorksDashboardV2/styles";
import PropTypes from 'prop-types';
class Tabs extends Component {
  constructor() {
    super();
    this.state = {
      activeTab: 0,
      label: "Calendar Integration",
      listOpen: false,
    };
    this.myref = React.createRef();
  }

  handleActiveTab = (active, tab) => this.setState({activeTab: active}, ()=>{
    this.setState({label: tab.label});
    this.setState((prev) => ({listOpen: !prev.listOpen}));
  });

  toggleList = () => {
    this.setState((prev) => ({listOpen: !prev.listOpen}));
  };

  componentDidMount() {
    document.addEventListener("mousedown", this.handleClick, false);
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClick, false);
  }

  handleClick = (e) => {
    if (this.myref && this.myref.current && this.myref.current.contains && !this.myref.current.contains(e.target) && this.state.listOpen === true ) {
      this.setState({listOpen: false});
    }
  };

  render() {
    const {activeTab, label, listOpen} = this.state;
    const {tabs} = this.props;
    return (
      <div className="tab-component-container">
        <div className="tab-wrapper">
          <div className="tab">
            <div className="tab-dropdown" ref={this.myref}>
              <button className="dropbtn" onClick={()=>this.toggleList()}>{label}
                {listOpen ? <i className="fa fa-caret-up" />

                  : <i className="fa fa-caret-down" /> }
              </button>
              {
                listOpen && tabs.map((tab, index) => (
                  <button
                    key = {index}
                    onClick={() => this.handleActiveTab(index, tab)}
                    className={`dropbtn ${activeTab === index && 'activeLabel'}`}>{tab.label}
                  </button>
                ))
              }
            </div>
            {
              tabs.map((tab, index) => (
                <div
                  key={index}
                  className={`tabContainer ${activeTab === index && 'activeTab'}`}>
                  <button
                    onClick={() => this.handleActiveTab(index, tab)}
                    className={`tabLabel ${activeTab === index && 'activeLabel'}`}>{tab.label}
                  </button>
                </div>
              ))
            }
          </div>
        </div>
        <div className="tab-component-parent">
          {
            tabs[activeTab].component
          }
        </div>
        <div className= "button-wrapper">
          <Button
            fontSize="13px"
            bgColor=" #0273b9"
            Align={1}
            noneFloat={1}
            capitalize={1}
            height="40px"
            width="184px"
            textColor="#ffffff"
            onClick={() => this.props.history.push('/contact')}
          >
            REQUEST A DEMO
          </Button>
        </div>
      </div>

    )
  }
}

Tabs.propTypes = {
  tabs: PropTypes.array,
  history: PropTypes.object
};

export default Tabs;
