import React from 'react';
import PropTypes from 'prop-types';
import { MainContainer, SubContainer, ImageContainer, DataContainer, Arrow } from './styles';
import { getListOfThingsToCompleteAPI } from '../../redux/actions';
import { connect } from 'react-redux';
import _ from 'lodash';
import { LoaderContainer } from '../Content/styles';
import Loading from '../Loading';
import isEmpty from "lodash/isEmpty";


class TaskToComplete extends React.Component {
  constructor(){
    super();
    this.state = {
      showTaskToComplete:true
    }
  }
  componentDidMount() {
    const{ fetchListOfThingsToComplate, listOfThingToComplate } = this.props;
    if(isEmpty(listOfThingToComplate)) {
      fetchListOfThingsToComplate();
    }
  }

  wellnessAssessment = () =>{
    const { history } = this.props;
    history.push("/wellness-assessment")
  }

  redirection = () =>{
    const { history, user } = this.props;
    history.push(`/profile/biometric/${user.uid}/multi-step/physician-form`)
  }

  render() { 
    const { user, listOfThingToComplate } = this.props;
    return(
      <MainContainer>
        {listOfThingToComplate && listOfThingToComplate.show_recommended ?
          <div>
            Recommended for You
          </div> : null}
        {!_.isEmpty(listOfThingToComplate) || !_.isEmpty(user) || !_.isNull(listOfThingToComplate) || !_.isNull(user) || !_.isUndefined(listOfThingToComplate) || !_.isUndefined(user)
          ?
          <div>
            {listOfThingToComplate && !listOfThingToComplate.sync_fitness_device ?
              <SubContainer onClick={this.props.displayDeviceModal}>
                <ImageContainer>
                  <img src="/public/images/NewDashboardV2/fitness.png" />
                </ImageContainer>
                <DataContainer color={'#FBA887'}>
                  <div>
                    {`Syncing Fitness Device`}
                  </div>
                  <div>
                    {`+${listOfThingToComplate.sync_fitness_device_points} pts`}
                  </div>
                </DataContainer>
                <Arrow>
                  <img src="/public/images/NewDashboardV2/arrow_right.png" />
                </Arrow>
              </SubContainer> : null}
            {/* <SubContainer>
              <ImageContainer>
                <img src="/public/images/NewDashboardV2/dental.png" />
              </ImageContainer>
              <DataContainer color={'#FD7175'}>
                <div>
                  {`Dental Visit`}
                </div>
                <div>
                  {`+25`}
                </div>
              </DataContainer>
              <Arrow>
                <img src="/public/images/NewDashboardV2/arrow_right.png" />
              </Arrow>
            </SubContainer> */}
            { listOfThingToComplate && !listOfThingToComplate.first_wba_completed ?
              <SubContainer onClick={this.wellnessAssessment}>
                <ImageContainer>
                  <img src="/public/images/NewDashboardV2/health.svg" />
                </ImageContainer>
                <DataContainer color={'#93A987'}>
                  <div>
                    {`Health Risk Assessment`}
                  </div>
                  <div>
                    {`+${listOfThingToComplate.first_wba_completed_points} pts`}
                  </div>
                </DataContainer>
                <Arrow>
                  <img src="/public/images/NewDashboardV2/arrow_right.png" />
                </Arrow>
              </SubContainer> : null}
            {listOfThingToComplate && !listOfThingToComplate.upload_physician_form ?
              <SubContainer onClick={()=>this.redirection()}>
                <ImageContainer>
                  <img src="/public/images/NewDashboardV2/biometric_icon.svg" />
                </ImageContainer>
                <DataContainer color={'#69C2FF'}>
                  <div>
                    {`Complete Biometric Screening`}
                  </div>
                  <div>
                    {`+${listOfThingToComplate.upload_physician_form_points} pts`}
                  </div>
                </DataContainer>
                <Arrow>
                  <img src="/public/images/NewDashboardV2/arrow_right.png" />
                </Arrow>
              </SubContainer> : null}
          </div>
          :
          <LoaderContainer><Loading /></LoaderContainer>
        }
      </MainContainer>
    );
  }
}

TaskToComplete.propTypes = {
  history: PropTypes.object.isRequired,
  user: PropTypes.object,
  listOfThingToComplate: PropTypes.object,
  displayDeviceModal: PropTypes.func.isRequired,
  fetchListOfThingsToComplate: PropTypes.func
}
const mapStateToProps = (state) => ({
  listOfThingToComplate: state.profileData.listOfThingToComplate,
});

const mapDispatchToProps = (dispatch) => ({
  fetchListOfThingsToComplate: () => dispatch(getListOfThingsToCompleteAPI())
});
export default connect(mapStateToProps, mapDispatchToProps)(TaskToComplete);
