import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {Img} from "react-image";

import Loading from '../Loading';
import LazyImage from '../common/LazyImage/LazyImage';

const ImageContainer = styled.div`
  width: 100%;
  height: ${({height}) => height ? height : '150px'};
  height: auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;

  img {
    cursor: pointer;
    width: 100%;
    height: 100%;
    border-radius: ${({recipe}) => recipe && '25px'};
  }
`;

const ThumbnailNew = ({src, alt, onClick, height, recipe,  ...rest}) => (
  <ImageContainer recipe={recipe} height={height}>
    <LazyImage
      src={src}
      alt={alt}
      onClick={onClick}
      loader={<Loading />}
      {...rest}
      unloader={
        <Img
          src='/public/images/placeholder/video-placeholder.jpg'
          alt={alt}
          onClick={onClick}
          {...rest}
        />
      }
    />
  </ImageContainer>
);

ThumbnailNew.propTypes = {
  src: PropTypes.string,
  alt: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  recipe: PropTypes.number,
  height: PropTypes.number,
};

export default ThumbnailNew;